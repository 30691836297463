import AxiosInstance from './AxiosInstance'; // AxiosInstance 불러오기

// 멤버 정보 조회 단건
export function fetchMember() {
  return AxiosInstance.get('/member').then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to fetch member");
  });
}

// 멤버 목록 조회 함수
export function fetchMembers() {
  return AxiosInstance.get('/members').then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to fetch members");
  });
}

// 멤버 등록 함수
export function registerMember(memberData) {
  return AxiosInstance.post('/member', memberData).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to register member");
  });
}

// 멤버 수정 함수
export function editMember(memberData) {
  return AxiosInstance.post('/member/edit', memberData).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to edit member");
  });
}

// 로그인 함수
export function loginMember(memberData) {
  return AxiosInstance.post('/login', memberData).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to login");
  });
}

// 멤버 이름 조회
export function fetchName(id) {
  const encodedId = encodeURIComponent(id);
  return AxiosInstance.get(`/member/id/${encodedId}`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch name");
    });
}

// 멤버 비밀번호 조회
export function fetchPassword(data) {
  const encodedPw = encodeURIComponent(data.password);
  return AxiosInstance.get(`/member/pw/${encodedPw}`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch password");
    });
}

// 멤버 이메일 조회
export function fetchEmail(email) {
  const encodedEmail = encodeURIComponent(email);
  return AxiosInstance.get(`/member/email/${encodedEmail}`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch email");
    });
}

// 멤버 전화번호 조회
export function fetchPhone(phone) {
  const encodedPhone = encodeURIComponent(phone);
  return AxiosInstance.get(`/member/phone/${encodedPhone}`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch phone");
    });
}

// 나이스 본인 인증 호출을 위한 정보 조회
export function niceMain(type) {
  return AxiosInstance.get(`/member/nice/main/${type}`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch niceMain data");
    });
}

// 오류 조회
export function getNiceError() {
  return AxiosInstance.get('/member/nice/error')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch nice error");
    });
}

// 본인인증 정보 조회
export function getNiceInfo() {
  return AxiosInstance.get('/member/nice/info')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch nice info");
    });
}

// 본인 정보 조회
export function getMemberInfo() {
  return AxiosInstance.get('/member/info')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch member info");
    });
}

// 본인 정보 조회
export function getMemberList() {
  return AxiosInstance.get('/member/list')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch member list");
    });
}

// 로그아웃 함수
export function logoutMember() {
  return AxiosInstance.get('/logout')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to logout");
    });
}

// 조직도 조회
export function getOrgChartData() {
  return AxiosInstance.get('/member/orgChart')
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to fetch org chart");
    });
}

// 추천아이디 검사
export function vaildRefID(id) {
  return AxiosInstance.post('/member/vaildRefID', { id })
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || "Failed to validate ref ID");
    });
}