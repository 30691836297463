import React from "react";
import Index from "../pages/mng/Index";
import Member from "../pages/mng/Member";
import MerchantSignup from "../pages/mng/Signup";
import Merchant from "../pages/mng/Merchant";
import MerchantEdit from "../pages/mng/Edit";

const Mng = [
  {
    path: "/mng",
    element: <Index />, 
  },
  {
    path: "/mng/member",
    element: <Member />, 
  },
  {
    path: "/mng/merchant",
    element: <Merchant />, 
  },
  {
    path: "/mng/merchant/signup",
    element: <MerchantSignup />, 
  },
  {
    path: "/mng/merchant/edit",
    element: <MerchantEdit />, 
  },
];

export default Mng;
