import React, { useEffect, useState, useMemo } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { useForm } from "react-hook-form";
import { editMerchant, fetchMerchantState } from "../../api/Merchant";
import { getMemberList } from "../../api/Member";
import classNames from "classnames";
import Loading from "../../components/common/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useLocation } from "react-router-dom";
import ImageUpload from "../../components/common/ImageUpload";
import Select from 'react-select';
import { useQuery } from "react-query";

const Edit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const swal = withReactContent(Swal);

  const { merchant } = useMemo(() => location.state, [location.state]);
  const [loading, setLoading] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [contentFiles, setContentFiles] = useState([]);

  const [initialhumbnailFile, setInitialThumbnailFile] = useState([]);
  const [initialContentFiles, setInitialContentFiles] = useState([]);
  const [member, setMember] = useState(null);
  const [state, setState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange", // 실시간 폼 유효성 검사 활성화
    defaultValues: {
      name: merchant?.name || "",
      url: merchant?.url || "",
      description: merchant?.description || "",
    }
  });

  useEffect(() => {
    if (merchant) {
      const API_URL = process.env.REACT_APP_API_URL;

      // 썸네일 이미지 로드
      if (merchant.thumbnail) {
        setInitialThumbnailFile([{
          preview: `${API_URL}/file/${merchant.thumbnail}` ,
          uniqueKey: `thumbnail-${Date.now()}-${Math.random()}`, // 고유한 키 생성
          edit: true,
          key: merchant.thumbnail
        }]);
      }
  
      // 설명 이미지 파일 로드
      const tempFile = [];

      for (let i = 1; i <= 5; i++) {
        if (merchant[`content_file_${i}`]) {
          tempFile.push({
            preview: `${API_URL}/file/${merchant[`content_file_${i}`]}` ,
            uniqueKey: `contentFiles_${i}-${Date.now()}-${Math.random()}`, // 고유한 키 생성
            edit: true,
            key: merchant[`content_file_${i}`]
          });
        }
      }

      setInitialContentFiles(tempFile);
    }
  }, [merchant]);  
  
  // 화면이 처음 렌더링될 때 유효성 검사 및 on 클래스 추가
  useEffect(() => {
    const fields = ["name", "url", "description"]; // 초기 유효성 검사를 진행할 필드들
    fields.forEach(async (field) => {
      const inputElement = document.getElementById(field)?.closest("div");
      if (inputElement) {
        const isValidField = await trigger(field); // 필드별로 유효성 검사
        if (isValidField) {
          inputElement.classList.add("on"); // 유효하면 on 클래스 추가
        } else {
          inputElement.classList.remove("on"); // 유효하지 않으면 on 클래스 제거
        }
      }
    });
  }, [trigger]); // trigger가 변경될 때마다 effect 실행

  const { isLoading: isMemberLoading } = useQuery("memberList", getMemberList, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        // 로드된 후 선택된 회원을 기본값으로 설정
        const member = data.list.find(option => option.value === merchant?.member_key);
        if (member) {
          setMember(member.label);
        }
      } else {
        swal.fire(
          <p className="swal_text">
            데이터를 불러오는 중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const { isLoading: isStateLoading } = useQuery("merchantState", fetchMerchantState, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setStateOptions(data.list);
        // 로드된 후 선택된 회원을 기본값으로 설정
        const state = data.list.find(option => option.value === merchant?.status_code);
        if (state) {
          setState(state);
        }
      } else {
        swal.fire(
          <p className="swal_text">
            데이터를 불러오는 중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const onSubmit = (data) => {
    setLoading(true);

    data.merchant_key = merchant.merchant_key;
    data.member_key = merchant.member_key;
    data.status_code = state ? state.value : null;
    
    editMerchant(data, thumbnailFile, contentFiles)
      .then((response) => {
        if (response.success) {
          swal.fire(<p className="swal_text">수정되었습니다.</p>);
          navigate("/mng/merchant");
        } else {
          setLoading(false);
          if (typeof response.msg === "string") {
            swal.fire(<p className="swal_text">{response.msg}</p>);
          } else {
            swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            가맹점 수정 중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  const handleThumbnailFilesChange = (files) => {
    setThumbnailFile(files); // 업로드된 파일을 상태로 저장
  };

  const handleContentFilesChange = (files) => {
    setContentFiles(files); // 업로드된 파일을 상태로 저장
  };

  const onValid = async (e) => {
    const fieldName = e.target.name;
    const value = e.target.value.trim();
    const inputField = e.target.closest("div");

    if (value) {
      setValue(fieldName, e.target.value);
      inputField.classList.toggle("on", await trigger(fieldName));
    } else {
      setValue(fieldName, ""); 
      inputField.classList.remove("on");
    }
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption);
  };

  return (
    <div className="merchant sub">
      <Header title="가맹점 수정" link="/mng/merchant" linkText="가맹점 목록" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <li>
            <div className="input readOnly">
              <label htmlFor="member">회원</label>
              <input id="member" value={member} readOnly />
            </div>
          </li>
          <li>
            <div className={classNames("input", { error: errors.name })}>
              <label htmlFor="name">가맹점 이름</label>
              <input
                id="name"
                {...register("name", {
                  required: "가맹점 이름을 입력해주세요.",
                  pattern: {
                    value: /^.{2,}$/,
                    message: "2자 이상 입력해주세요.",
                  },
                })}
                placeholder="가맹점 이름 입력"
                onBlur={onValid}
              />
            </div>
            {errors.name && <p>{errors.name.message}</p>}
          </li>
          <li>
            <div className={classNames("input", { error: errors.url })}>
              <label htmlFor="url">홈페이지 주소</label>
              <input 
                id="url" 
                {...register("url", {
                  pattern: {
                    // URL에 대한 정규 표현식 (http, https를 포함한 기본적인 URL 형식 검증)
                    value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                    message: "올바른 홈페이지 주소를 입력해주세요.",
                  },
                })}
                placeholder="홈페이지 주소 입력" 
                onBlur={onValid}
              />
            </div>
            {errors.url && <p>{errors.url.message}</p>}
          </li>
          <li>
            <div className={classNames("input", { error: errors.description })}>
              <label htmlFor="description">간단한 설명</label>
              <textarea 
                id="description" 
                {...register("description")} 
                placeholder="간단한 설명 입력"
                onBlur={onValid}
              ></textarea>
            </div>
            {errors.description && <p>{errors.description.message}</p>}
          </li>
          <li>
            <div className={classNames("input select on")}>
              <label htmlFor="state">상태</label>
              <Select 
                options={stateOptions} 
                value={state} 
                onChange={handleStateChange} 
              />
            </div>
          </li>
          <li>
            <div className="image_upload">
              <span>썸네일 등록</span>
              <ImageUpload onFilesChange={handleThumbnailFilesChange} initialFiles={initialhumbnailFile} />
              <p>5MB 이하의 jpg, gif, png 파일만 가능합니다.</p>
            </div>
          </li>
          <li>
            <div className="image_upload">
              <span>가맹점 설명 이미지 등록</span>
              <ImageUpload maxFiles={5} onFilesChange={handleContentFilesChange} initialFiles={initialContentFiles} />
              <p>5MB 이하의 jpg, gif, png 파일 5개까지 가능합니다.</p>
            </div>
          </li>
          <li className="mt-20">
            <button type="submit" className="button" disabled={!isValid}>
              수정하기
            </button>
          </li>
        </ul>
      </form>

      <Menu />

      {(isStateLoading || isMemberLoading || loading) && <Loading />}
    </div>
  );
};

export default Edit;
