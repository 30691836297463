import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getMemberInfo } from "../../api/Member";
import Loading from "../../components/common/Loading";

function Index() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false); // 이름

  const { isLoading : isMemberLoading } = useQuery('memberInfo', getMemberInfo, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setIsAdmin(data.is_admin);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  });
  
  return (
    <div className="mypage sub">
      <Header title="관리자" link="/home" linkText="홈으로" />

      <div className="content">
        {isAdmin && <ul className="link-list">
          <li>
            <Link to="/mng/member" className="mypage_05">
              회원 목록
            </Link>
          </li>
          <li>
            <Link to="/mng/merchant" className="mypage_04">
              가맹점 목록
            </Link>
          </li>
        </ul>}
      </div>
      
      <Menu />

      {isMemberLoading && <Loading />}
    </div>
  );
}

export default Index;
