import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getMemberInfo, logoutMember } from "../../api/Member";
import useNumberFormat from "../../hooks/useNumberFormat";
import Loading from "../../components/common/Loading";

function MyPage() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const formatNumber = useNumberFormat();

  const [name, setName] = useState(""); // 이름
  const [id, setID] = useState(""); // 아이디
  const [point, setPoint] = useState(""); // 포인트
  const [logoutEnabled, setLogoutEnabled] = useState(false); // 로그아웃 트리거 상태

  const handleCopyLink = () => {
    const domain = window.location.origin; // 현재 페이지의 도메인 주소를 가져옴
    const encodedID = btoa(id); // 아이디 값을 URL 인코딩
    const referralLink = `${domain}/ref/${encodedID}`;
    
    navigator.clipboard.writeText(referralLink).then(() => {
      swal.fire(
        <p className="swal_text">추천 링크가 복사되었습니다.</p>
      );
    }).catch((error) => {
      swal.fire(
        <p className="swal_text">
          링크 복사 중 오류가 발생했습니다.
          <br />
          다시 시도해주세요.
        </p>
      );
    });
  };

  const handleLogout = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음
    setLogoutEnabled(true); // 로그아웃 쿼리 활성화
  };

  const { isLoading: isLogoutLoading } = useQuery('logoutMember', logoutMember, {
    enabled: logoutEnabled, // logoutEnabled가 true일 때만 실행
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        navigate("/");
      } else {
        swal.fire(<p className="swal_text">{data.msg}</p>);
        setLogoutEnabled(false); // 실행 후 비활성화
      }
    },
    onError: () => {
      navigate("/");
    }
  });

  const { isLoading : isMemberLoading } = useQuery('memberInfo', getMemberInfo, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setName(data.name);
        setID(data.id);
        setPoint(formatNumber(data.max_points));
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  });

  const handleIncompleteMenu = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음
    swal.fire(<p className="swal_text">준비 중입니다.</p>);
  };

  const isLoading = isMemberLoading || isLogoutLoading;
  
  return (
    <div className="mypage sub">
      <Header title="내 정보" link="/home" linkText="홈으로" />

      <div className="content">
        <div className="point-box">
          <h2>
            {name} <span>({id})</span>
          </h2>
          <div>
            <span>누적포인트</span>
            <p>
              {point}
              <span>Point</span>
            </p>
          </div>
        </div>

        <ul className="link-list">
          <li>
            <button type="button" className="mypage_06" onClick={handleIncompleteMenu}>
              포인트 충전
            </button>
          </li>
          <li>
            <Link to="/orgChart" className="mypage_03">
              조직도
            </Link>
          </li>
        </ul>

        <div className="btn-list">
          <h3>나의 추천 링크</h3>

          <ul>
            <li>
              <button type="button" className="link_copy" onClick={handleCopyLink}>
                추천 링크 복사
              </button>
            </li>
          </ul>
        </div>

        <div className="menu-list">
          <h3>내 정보 관리</h3>

          <ul>
            <li>
              <Link to="/edit/check" className="mypage_01">
                내 정보 변경
              </Link>
            </li>
            <li>
              <Link to="#" className="mypage_02" onClick={handleLogout}>
                로그아웃
              </Link>
            </li>
          </ul>
        </div>
      </div>
      
      <Menu />

      {isLoading && <Loading />}
    </div>
  );
}

export default MyPage;
