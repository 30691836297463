import AxiosInstance from './AxiosInstance'; // AxiosInstance 불러오기

// 가맹점 등록 함수
export function registerMerchant(data, thumbnailFile, contentFiles) {
  const formData = new FormData();

  // 데이터를 FormData에 추가
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  // 파일을 FormData에 추가
  thumbnailFile.forEach((file, index) => {
    formData.append("thumbnail", file); 
  });

  contentFiles.forEach((file, index) => {
    formData.append(`content_file_${index + 1}`, file); 
  });

  return AxiosInstance.post('/merchant', formData).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to register merchant");
  });
}

// 가맹점 수정 함수
export function editMerchant(data, thumbnailFile, contentFiles) {
  const formData = new FormData();

  // 데이터를 FormData에 추가
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  // 파일을 FormData에 추가
  thumbnailFile.forEach((file, index) => {
    formData.append("thumbnail", file); 
  });

  contentFiles.forEach((file, index) => {
    formData.append(`content_file_${index + 1}`, file); 
  });

  return AxiosInstance.post('/merchant/edit', formData).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to edit merchant");
  });
}

// 가맹점 목록 조회 함수
export function fetchMerchant() {
  return AxiosInstance.get('/merchant').then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to get Merchant");
  });
}

// 가맹점 조회 함수
export function fetchMerchantList() {
  return AxiosInstance.get('/merchant/list').then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to get Merchant list");
  });
}

// 가맹점 상태코드 조회 함수
export function fetchMerchantState() {
  return AxiosInstance.get('/merchant/state').then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed to get Merchant State");
  });
}