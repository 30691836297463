import React, { useState } from "react";
import Header from "../../inc/Header";
import Menu from "../../inc/Menu";
import Loading from "../../../components/common/Loading";
import { fetchMember, editMember } from "../../../api/Member";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import classNames from "classnames";

function Index() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    trigger,
    clearErrors,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(""); // 이름 상태
  const [phone, setPhone] = useState(""); // 휴대폰 번호 상태
  const [email, setEmail] = useState(""); // 이메일
  const [id, setId] = useState(""); // 아이디
  const [referrer_id, setReferrerId] = useState(""); // 추천 아이디
  const [wallet_address, setWalletAddress] = useState(""); // 추천 아이디
  const [isChanged, setIsChanged] = useState(false);

  const watchedPassword = watch("password");
  const watchedPasswordChk = watch("password_chk");
  const watchedWalletAddress = watch("wallet_address");

  const { isLoading } = useQuery('member', fetchMember, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setName(data.member.name);
        setPhone(data.member.phone || "-");
        setEmail(data.member.email || "-");
        setId(data.member.id);
        setReferrerId(data.member.referrer_id);
        setWalletAddress(data.member.wallet_address);
        setValue("wallet_address", data.member.wallet_address);

        const field = "wallet_address"; // 초기 유효성 검사를 진행할 필드들
        const inputElement = document.getElementById(field)?.closest("div");
        if (inputElement) {
          const isValidField = trigger(field); // 필드별로 유효성 검사
          if (isValidField) {
            const value = document.getElementById(field).value.trim();
  
            if (value) {
              inputElement.classList.add("on"); // 유효하면 on 클래스 추가
            } else {
              inputElement.classList.remove("on"); // 유효하지 않으면 on 클래스 제거
            }
          } else {
            inputElement.classList.remove("on"); // 유효하지 않으면 on 클래스 제거
          }
        }
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  });

  const onSubmit = (data) => {
    setLoading(true);
    editMember(data)
      .then((response) => {
        setLoading(false);

        if (response.success) {
          setIsChanged(false);
          reset();
          setWalletAddress(data.wallet_address);
          document.getElementById("wallet_address").value = data.wallet_address;
          document.getElementById("password")?.closest("div").classList.remove("on"); // 유효하지 않으면 on 클래스 제거
          document.getElementById("password_chk")?.closest("div").classList.remove("on"); // 유효하지 않으면 on 클래스 제거

          swal.fire(<p className="swal_text">정보 변경이 완료되었습니다.</p>);
        } else {
          if (response.message) {
            swal.fire(<p className="swal_text">{response.msg}</p>);
          } else {
            swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            정보 변경중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  const onValid = async (e) => {
    const fieldName = e.target.name;
    const value = e.target.value.trim();
    const inputField = e.target.closest("div");

    if (value) {
      setValue(fieldName, e.target.value);
      const isValidField = await trigger(fieldName);

      if (fieldName === "password_chk") {
        if (value !== watchedPassword) {
          setError("password_chk", { message: "비밀번호가 일치하지 않습니다." });
          inputField.classList.remove("on");
        } else {
          clearErrors("password_chk");
          inputField.classList.add("on");
        }
      } else {
        inputField.classList.toggle("on", isValidField);
        inputField.classList.toggle("error", !isValidField);
      }
    } else {
      setValue(fieldName, ""); 
      inputField.classList.remove("on");
    }

    if (watchedPassword && errors.password === undefined) {
      if (watchedPasswordChk && errors.password_chk === undefined) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }
    } else if (watchedWalletAddress !== wallet_address && errors.wallet_address === undefined) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  };

  return (
    <div className="mypage sub">
      <Header title="내 정보 변경" link="/mypage" linkText="내 정보" />

      <div className="content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <div className="input readOnly">
                <label htmlFor="name">이름</label>
                <input id="name" placeholder="이름입력" value={name} readOnly />
              </div>
            </li>
            <li>
              <div className="input readOnly">
                <label htmlFor="phone">휴대폰</label>
                <input id="phone" placeholder="휴대폰 번호 입력" value={phone} readOnly />
              </div>
            </li>
            <li>
              <div className="input readOnly">
                <label htmlFor="email">이메일</label>
                <input id="email" placeholder="이메일 주소 입력" value={email} readOnly />
              </div> 
            </li>
            <li>
              <div className="input readOnly">
                <label htmlFor="id">아이디</label>
                <input id="id" placeholder="아이디 입력" value={id} readOnly />
              </div> 
            </li>
            <li>
              <div className="input readOnly">
                <label htmlFor="referrer_id">추천 아이디</label>
                <input id="referrer_id" placeholder="추천 아이디 입력" value={referrer_id} readOnly />
              </div> 
            </li>
            <li>
              <div className={classNames("input", { error: errors.password })}>
                <label htmlFor="password">비밀번호</label>
                <input
                  id="password"
                  type="password"
                  {...register("password", {
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*_+=?,.-])[A-Za-z\d!@#$%^&*_+=?,.-]{8,20}$/,
                      message: "8~20자 이내 영문 소문자, 숫자, 특수문자를 포함하여 입력해주세요.",
                    },
                  })}
                  placeholder="비밀번호 입력"
                  onInput={onValid}
                />
              </div>
              {errors.password && <p>{errors.password.message}</p>}
            </li>
            <li>
              <div className={classNames("input", { error: errors.password_chk })}>
                <label htmlFor="password_chk">비밀번호 확인</label>
                <input id="password_chk" type="password" {...register("password_chk")} placeholder="비밀번호 재입력" onInput={onValid} />
              </div>
              {errors.password_chk && <p>{errors.password_chk.message}</p>}
            </li>
            <li>
              <div className={classNames("input")}>
                <label htmlFor="wallet_address">지갑주소</label>
                <input
                  id="wallet_address"
                  {...register("wallet_address", {
                    validate: value => {
                      if (value && !/^0x[a-fA-F0-9]{40}$/.test(value)) {
                        return "유효한 지갑주소를 입력해주세요.";
                      }
                      return true;
                    }
                  })}
                  placeholder="지갑주소  입력"
                  onInput={onValid}
                />
              </div>
              {errors.wallet_address && <p>{errors.wallet_address.message}</p>}
            </li>
          </ul>

          <button type="submit" className="button mt-42" disabled={!isChanged}>
            수정하기
          </button>
        </form>
      </div>

      <Menu />

      {(isLoading || loading) && <Loading />}
    </div>
  );
}

export default Index;
