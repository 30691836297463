import AxiosInstance from './AxiosInstance';

// 포인트 내역 조회
export function getPointHist(params) {
  return AxiosInstance.post('/point/hist', params).then((response) => {
    return response.data;
  }).catch((error) => {
    throw new Error(error.response?.data?.message || "Failed");
  });
}

// 포인트 전송 API
export function sendPoint(data) {
  return AxiosInstance.post('/point/send', data)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response?.data?.message || '포인트 전송 실패');
    });
}