import axios from 'axios';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;
let failedQueue = [];
let isRefreshing = false;
let refreshTokenPromise = null;

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 리프레시 토큰 요청 함수
const refreshAccessToken = async () => {
  try {
    const refreshToken = sessionStorage.getItem('refresh_token'); // 리프레시 토큰 가져오기
    const response = await axios.post(
      `${API_URL}/refresh`, 
      {}, 
      {
        headers: {
          'Authorization': `Bearer ${refreshToken}` // 리프레시 토큰을 Authorization 헤더에 포함
        }
      }
    );
    return response.data.access_token;
  } catch (error) {
    throw new Error('Failed to refresh token');
  }
};

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // 401 오류 (토큰 만료) 발생 시 처리
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokenPromise = refreshAccessToken()
          .then((newToken) => {
            sessionStorage.setItem('access_token', newToken);
            processQueue(null, newToken);
            return newToken;
          })
          .catch((err) => {
            processQueue(err, null);

            // 리프레시 토큰 실패 시 리다이렉트 및 오류 메시지 처리
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');

            Swal.fire(          
              <p className="swal_text">
                로그인중 오류가 발생했습니다.
                <br />
                잠시후 다시 시도해주세요.
              </p>
            ).then(() => {
              window.location.href = '/'; // /로 리다이렉트
            });

            throw err;
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      // Refresh token promise가 완료될 때까지 대기
      return refreshTokenPromise
        .then((newToken) => {
          // 새로운 토큰으로 요청 재시도
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          return AxiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export default AxiosInstance;
