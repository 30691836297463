import React from "react";
import Main from "../pages/customer/Main";
import Terms from "../pages/customer/signup/Terms";
import SignUp from "../pages/customer/signup/SignUp";
import Complete from "../pages/customer/signup/Complete";
import Ref from "../pages/customer/signup/Ref";
import MyPage from "../pages/customer/MyPage";
import OrgChart from "../pages/customer/OrgChart"; 
import Home from "../pages/customer/Home";
import Merchant from "../pages/customer/Merchant";
import MerchantDetail from "../pages/customer/MerchantDetail";
import Edit from "../pages/customer/edit/Index";
import Check from "../pages/customer/edit/Check";
import Point from "../pages/customer/Point";

const Customer = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "ref/:id",
    element: <Ref />,
  },
  {
    path: "myPage",
    element: <MyPage />,
  },
  {
    path: "point",
    element: <Point />,
  },
  {
    path: "orgChart",
    element: <OrgChart />,
  },
  {
    path: "merchant",
    element: <Merchant />,
  },
  {
    path: "merchant/detail",
    element: <MerchantDetail />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "signup/terms/:type",
    element: <Terms />,
  },
  {
    path: "signup/complete",
    element: <Complete />,
  },
  {
    path: "signup/nice/:type",
    loader: ({ params }) => {
      const { type } = params;
      // 파이썬 서버의 페이지로 이동
      window.location.href = `/api/member/nice/main/${type}`;
      return null; // 리턴 값은 필수이므로 null 반환
    },
  }, 
  {
    path: "edit",
    element: <Edit />,
  },
  {
    path: "edit/check",
    element: <Check />,
  },
];

export default Customer;
