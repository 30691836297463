import React, { useState } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { useLocation } from "react-router-dom";
import { QRCode } from "react-qrcode-logo"; // QR 코드 생성 라이브러리

const MerchantDetail = () => {
  let API_URL = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const merchant = location.state || {}; // state에서 데이터를 받음
  const [showQRCode, setShowQRCode] = useState(false); // 모달 표시 상태

  // QR 코드 버튼 클릭 시 모달을 열고 merchant_key 설정
  const handleQRCodeClick = () => {
    setShowQRCode(true); // QR 코드 모달 열기
  };

  // 모달 닫기 함수
  const handleCloseModal = () => {
    setShowQRCode(false); // QR 코드 모달 닫기
  };

  console.dir(merchant);

  return (
    <div className="merchant sub">
      <Header title="가맹점" link={merchant.link} linkText={merchant.linkText} />

      <div className="content">
        {merchant.content_file_1 && <img src={`${API_URL}/file/${merchant.content_file_1}`} alt="상세 내용" />}
        {merchant.content_file_2 && <img src={`${API_URL}/file/${merchant.content_file_2}`} alt="상세 내용" />}
        {merchant.content_file_3 && <img src={`${API_URL}/file/${merchant.content_file_3}`} alt="상세 내용" />}
        {merchant.content_file_4 && <img src={`${API_URL}/file/${merchant.content_file_4}`} alt="상세 내용" />}
        {merchant.content_file_5 && <img src={`${API_URL}/file/${merchant.content_file_5}`} alt="상세 내용" />}

        {merchant.is_owner && <div className="btn-list">
          <ul>
            <li>
              <button type="button" className="qr-code" onClick={handleQRCodeClick}>
                QR code
              </button>
            </li>
          </ul>
        </div>
        }
      </div>

      <Menu />

      {showQRCode && (
        <div className="modal">
          <div className="modal-content">
            <button type="button" className="close" onClick={handleCloseModal}>close</button>
            <QRCode value={`${merchant.merchant_key}`} size={256} /> {/* QR 코드 생성 */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantDetail;
